import * as React from "react"

/* Import Global Component(s) */
import Header from "~components/header/header"
import Seo from "~components/seo/seo"
import CompositionsManager from "~components/compositionsManager/compositionsManager"

/* Import Scene Specific Components */
import Harpy from "~components/compositionsManager/components/harpy"

/* Import Local Style(s) */
import * as styles from "./index.module.scss"

const HarpyPage = () => {
  return (
    <div className={`index debugging ${styles.index}`}>
      <Header />
      <CompositionsManager>
        <Harpy editorMode={true} />
      </CompositionsManager>
    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default HarpyPage
